<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="4">
              <CSelect
                :placeholder="$t('search_a') + $t('phone_book')"
                :options="phoneBooksOptions"
                :value.sync="searchData.phone_book_id"
                :isValid="valid_searchData_phone_book_id"
              />
            </CCol>
            <CCol col="12" sm="3">
              <CSelect
                :placeholder="$t('search_a') + $t('tag')"
                :options="tagsOptions"
                :value.sync="searchData.tag_id"
                :isValid="valid_searchData_tag_id"
              />
            </CCol>
            <CCol col="12" sm="3">
              <CInput
                :placeholder="$t('search_a') + $t('phone_number')"
                v-model="searchData.number"
              />
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block :disabled="valid_searchData_phone_book_id || valid_searchData_tag_id ? false : true" @click="getPhones()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="8" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="success" block @click="() => importPhonesModal = { modal: true }">{{ $t('import_phone_numbers') }}</CButton>
            </CCol>
            <CCol col="12" sm="2">
              <vue-excel-xlsx
                class="btn btn-primary btn-block"
                :disabled="data.length === 0"
                :data="excelData()"
                :columns="excelColumns"
                :fileName="excelFilename()"
                :sheetName="$t('phone_search_results')"
                >
                {{ $t('export_search_results') }}
              </vue-excel-xlsx>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <font slot="last_dialing_logs" slot-scope="props" v-html="props.row.last_dialing_logs" />
            <font slot="created_at" slot-scope="props">
              {{ $moment(props.row.created_at).format('YYYY-MM-DD HH:mm:ss') }}
            </font>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="danger" v-bind="{ variant: 'outline' }" @click="() => deletePhoneModal = { data: props.row, modal: true }">{{ $t('delete') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <ImportPhonesModal :data="importPhonesModal.data" :show="importPhonesModal.modal" @showUpdate="show => importPhonesModal.modal = show" @formSubmit="getPhoneBooks(); valid_searchData_phone_book_id ? getPhones() : false" />

      <DeletePhoneModal :data="deletePhoneModal.data" :show="deletePhoneModal.modal" @showUpdate="show => deletePhoneModal.modal = show" @formSubmit="getPhoneBooks(); valid_searchData_phone_book_id ? getPhones() : false" />

    </CCol>
  </CRow>
</template>

<script>
import ImportPhonesModal from './modal/ImportPhonesModal'
import DeletePhoneModal from './modal/DeletePhoneModal'

export default {
  name: 'phones',
  components: {
    ImportPhonesModal,
    DeletePhoneModal,
  },
  data () {
    return {
      columns: [ 'phone_book.name', 'number', 'name', 'tag_names', 'remark', 'last_dialing_logs', 'created_at', 'action' ],
      data: [],
      options: {
        headings: {
          'phone_book.name': this.$t('phone_book'),
          number: this.$t('phone_number'),
          name: this.$t('phone_name'),
          tag_names: this.$t('tags'),
          remark: this.$t('remark'),
          last_dialing_logs: this.$t('last_dialing_logs'),
          created_at: this.$t('create_time'),
          action: this.$t('action')
        },
        sortable: [ 'phone_book.name', 'number', 'name', 'tag_names', 'remark', 'last_dialing_logs', 'created_at' ],
        filterable: [ 'phone_book.name', 'number', 'name', 'tag_names', 'remark', 'last_dialing_logs', 'created_at' ]
      },
      excelColumns: [
        { label: this.$t('phone_book'), field: 'phone_book.name' },
        { label: this.$t('phone_number'), field: 'number' },
        { label: this.$t('phone_name'), field: 'name' },
        { label: this.$t('tags'), field: 'tag_names' },
        { label: this.$t('remark'), field: 'remark' },
        { label: this.$t('last_dialing_logs'), field: 'last_dialing_logs', dataFormat: v => v.replace(/<br\s*\/?>/gi, '\n') },
        { label: this.$t('create_time'), field: 'created_at', dataFormat: v => this.$moment(v).format('YYYY-MM-DD HH:mm:ss') }
      ],
      phoneBooksOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('phone_book') + '...' }
      ],
      tagsOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('tag') + '...' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: { phone_book_id: null, tag_id: null, number: '' },
      importPhonesModal: { data: {}, modal: false },
      deletePhoneModal: { data: {}, modal: false },
    }
  },
  computed: {
    valid_searchData_phone_book_id () {
      return this.searchData.phone_book_id ? true : (this.searchData.tag_id ? null : false)
    },
    valid_searchData_tag_id () {
      return this.searchData.tag_id ? true : (this.searchData.phone_book_id ? null : false)
    },
  },
  mounted: function () {
    this.getPhoneBooks()
    this.getTags()
  },
  methods: {
    getPhoneBooks () {
      const loader = this.$loading.show()
      this.$store.dispatch('getPhoneBooks').then(res => {
        this.phoneBooksOptions = [{ value: null, label: this.$t('please_select_a') + this.$t('phone_book') + '...' }]
        const groupData = this.$_.groupBy(this.$_.sortBy(res, row => -this.$moment(row.created_at).unix()), row => this.$moment(row.created_at).format('YYYY-MM'))
        for (let [key, val] of Object.entries(groupData)) {
          this.phoneBooksOptions.push({ value: key, label: key, disabled: true })
          for (const i in val) {
            this.phoneBooksOptions.push({ value: val[i].id, label: '　　' + val[i].name + ' (' + val[i].admin_name + ')', name: val[i].name })
          }
        }
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    getTags () {
      const loader = this.$loading.show()
      this.$store.dispatch('getTags').then(res => {
        this.tagsOptions = [{ value: null, label: this.$t('please_select_a') + this.$t('tag') + '...' }]
        for (const i in res) {
          this.tagsOptions.push({ value: res[i].id, label: res[i].name })
        }
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    getPhones () {
      if (!(this.searchData.phone_book_id || this.searchData.tag_id)) {
        this.$swal('Warning', this.$t('please_select_a') + this.$t('phone_book') + this.$t('or') + this.$t('tag'), 'warning')
        return
      }
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getPhones', this.$_.clone(this.searchData)).then(res => {
        for (const i in res) {
          res[i].tag_names = this.$_.pluck(res[i].tags, 'name').join(', ')
          res[i].last_dialing_logs = this.$_.map(res[i].dialing_logs, v => v.connected ? `[${this.$moment(v.created_at).format('YYYY-MM-DD HH:mm:ss')}] ${this.$t('connected')} (${v.duration} ${this.$t('seconds')})` : `[${this.$moment(v.created_at).format('YYYY-MM-DD HH:mm:ss')}] ${this.$t('not_connected')}`).join('<br>')
        }
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    excelData () {
      return this.data
    },
    excelFilename () {
      const phone_book_name = this.searchData.phone_book_id ? this.$_.find(this.phoneBooksOptions, option => option.value === this.searchData.phone_book_id).name : 'AllPhoneBooks'
      const tag_name = this.searchData.tag_id ? this.$_.find(this.tagsOptions, option => option.value === this.searchData.tag_id).label : 'AllTags'
      return `${this.$route.name}-[${phone_book_name}]_${tag_name}_export_${this.$moment().unix()}`
    }
  }
}
</script>
