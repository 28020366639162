<template>
  <CAlert color="info">
    <h3>重要提醒：</h3>
    <ul>
      <li><strong>電話號碼格式：</strong>請確保輸入的電話號碼遵循台灣手機號碼格式。有效的格式範例包括「0912345678」、「912345678」、「+88612345678」、「+8860912345678」。請確保您的號碼格式正確，以避免匯入錯誤。</li>
      <li><strong>儲存檔案時，請確保使用 UTF-8 編碼：</strong>這非常重要，因為如果檔案未使用 UTF-8 編碼，上傳到系統後中文字將顯示為亂碼。使用正確的編碼有助於保證資料的準確性和可讀性。</li>
      <li><strong>請僅使用逗號（,）作為分隔符號：</strong>確保檔案儲存為逗號分隔值（CSV）格式。</li>
      <li><strong>上傳前檢查：</strong>上傳檔案前，請再次檢查資料的準確性和格式的正確性。</li>
    </ul>
    <p>遵循以上指導方針，您可以幫助確保資料匯入的順利進行。若有任何疑問或需要進一步的協助，請聯繫系統管理員或技術支援。</p>
  </CAlert>
</template>

<script>
export default {
  name: 'ImportInstructions'
}
</script>
