<template>
  <CModal
    :title="$t('import_phone_numbers')"
    size="lg"
    color="success"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12">
        <ImportInstructions />
      </CCol>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('phone_book')"
          :options="phoneBooksOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="formData.phone_book_id"
          :isValid="valid_formData_phone_book_id"
        />
      </CCol>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('phone_number') }}
          </label>
          <div class="col-sm-9">
            <div class="input-group">
              <div class="custom-file">
                <input type="file" :class="['custom-file-input', valid_formData_file ? 'is-valid' : 'is-invalid']" id="excelFile" ref="excelFile" accept=".csv" @change="importExcel">
                <label class="custom-file-label" for="excelFile" :data-browse="$t('import_phone_numbers')">{{ this.formData.file ? this.formData.file.name : 'Choose file...' }}</label>
              </div>
            </div>
            <small class="form-text text-muted w-100">
              {{ $t('import_file_type_restriction', { type: 'CSV' }) }}<br>
              {{ $t('import_file_size_limit', { size: '1MB' }) }}<br>
              {{ $t('limit_on_the_number_of_imported_data', { count: '20,000.' }) }}<br>
              {{ $t('auto_split_remark', { count: '20,000.' }) }}
            </small>
          </div>
        </div>
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
import ImportInstructions from './ImportInstructions'

export default {
  name: 'ImportPhonesModal',
  components: {
    ImportInstructions,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      phoneBooksOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('phone_book') + '...' }
      ],
      formData: { phone_book_id: null, file: null },
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.getPhoneBooks(() => {
          this.$refs.excelFile.value = null
          this.formData = { phone_book_id: null, file: null }
        })
      }
    }
  },
  computed: {
    valid_formData_phone_book_id () {
      return this.formData.phone_book_id ? true : false
    },
    valid_formData_file () {
      return this.formData.file ? true : false
    },
    valid_submit () {
      return this.valid_formData_phone_book_id && this.valid_formData_file
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    getPhoneBooks (cb) {
      const loader = this.$loading.show()
      this.$store.dispatch('getPhoneBooks').then(res => {
        this.phoneBooksOptions = [{ value: null, label: this.$t('please_select_a') + this.$t('phone_book') + '...' }]
        const groupData = this.$_.groupBy(this.$_.sortBy(res, row => -this.$moment(row.created_at).unix()), row => this.$moment(row.created_at).format('YYYY-MM'))
        for (let [key, val] of Object.entries(groupData)) {
          this.phoneBooksOptions.push({ value: key, label: key, disabled: true })
          for (const i in val) {
            this.phoneBooksOptions.push({ value: val[i].id, label: '　　' + val[i].name + ' (' + val[i].admin_name + ')', name: val[i].name })
          }
        }
        loader.hide()
        cb(true)
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
        cb(false)
      })
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        const formData = new FormData()
        formData.append('phone_book_id', this.formData.phone_book_id)
        formData.append('file', this.formData.file)
        this.$store.dispatch('importPhones', formData).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$refs.excelFile.value = null
          this.formData = { phone_book_id: null, file: null }
          this.$swal('Success', this.$t('created_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
    importExcel (e) {
      const files = e.target.files
      if (!files || !files.length > 0) {
        this.$refs.excelFile.value = null
        this.formData.file = null
      } else if (!/\.(csv)$/.test(files[0].name.toLowerCase()) || (files[0].type !== 'text/csv')) {
        this.$refs.excelFile.value = null
        this.formData.file = null
        this.$swal('Warning', '上傳檔案格式不正確，請上傳csv格式', 'warning')
      } else if (files[0].size > 1 * 1024 * 1024) {
        this.$swal('Warning', '上傳檔案大小超出上限，上限1MB。(檔案大小: ' + Math.round(files[0].size / 1024 / 1024).toString() + 'MB)', 'warning')
      } else {
        this.formData.file = files[0]
      }
    },
  },
  beforeDestroy: function () {
  },
}
</script>
